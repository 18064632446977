.logo_top {
  max-width: 280px;
  margin: auto;
  display: block;
  background: white;
  border-radius: 12px;
}
img.logo2 {
  max-width: 70px;
  position: absolute;
  left: 8px;
  top: 5px;
}

.fxd {
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 420px;
}
img.logo2m {
  max-width: 120px;
}
@media all and (max-width: 768px) {
  .logo_top {
    max-width: 158px;
  }
  img.logo2m {
    max-width: 90px;
  }
  .fxd {
    justify-content: center;
  }
  .logo_top {
    margin: 0;
  }
}
